import React from "react"

import { withForgot } from "./withForgot"
import { ForgotForm } from "./Form/ForgotForm"
import { Layout } from "../Layout/Layout"
import { Description } from "../Layout/LayoutStyles"

export const Forgot = withForgot(
  ({ title, description, image }): JSX.Element => (
    <Layout title={title} image={image}>
      <Description>{description}</Description>
      <ForgotForm />
    </Layout>
  )
)
