import React from "react"

import { useImage } from "../../../hooks/useImage"

export const withForgot =
  Component =>
  ({ name = "Forgot", page }) => {
    const { getGatsbyImage } = useImage()

    const { title, description, image: originalImage } = page || {}

    const image = getGatsbyImage(originalImage)

    Component.displayName = name
    return <Component title={title} image={image} description={description} />
  }
