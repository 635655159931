import React from "react"

import { withForgotForm } from "./withForgotForm"
import { TextField } from "../../../TextField/TextField"
import { Submit, Errors, Error } from "../../Layout/LayoutStyles"

export const ForgotForm = withForgotForm(
  ({
    data,
    handleChange,
    handleSubmit,
    loading,
    success,
    errors,
    additionalSubmitButton,
    additionalEmailLabel,
    additionalSuccessMessage,
  }): JSX.Element => (
    <form onSubmit={handleSubmit}>
      <TextField
        name="email"
        type="email"
        value={data?.email}
        onChange={handleChange}
        label={additionalEmailLabel}
        required
        spacing
      />
      {errors?.length > 0 && (
        <Errors>
          {errors?.map((error, index) => (
            <Error key={index}>
              <p>{error?.message}</p>
            </Error>
          ))}
        </Errors>
      )}
      {success ? <Error>{additionalSuccessMessage}</Error> : null}
      <Submit size="primary" wide="true" type="submit" disabled={loading}>
        {additionalSubmitButton}
      </Submit>
    </form>
  ),
)
